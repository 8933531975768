// src/components/HeroSection.js

import React from "react";
import { useSpring, animated } from "@react-spring/web";

const MainSection = () => {
  const shape1Springs = useSpring({
    from: { left: 36, top: 0 },
    to: [
      { left: -100, top: 70 },
      { left: 20, top: 150 },
      { left: 50, top: 100 },
      { left: 0, top: 0 },
    ],
    delay: 1000,
    loop: true,
    config: {
      duration: 2500,
    },
  });

  const shape2Springs = useSpring({
    from: { left: 0, top: -64 },
    to: [
      { left: -100, top: 70 },
      { left: 20, top: 150 },
      { left: 50, top: 100 },
      { left: 0, top: 0 },
    ],
    delay: 1000,
    loop: true,
    config: {
      duration: 2500,
    },
  });

  const shape3Springs = useSpring({
    from: { right: 80, top: 0 },
    to: [
      { left: -100, top: 70 },
      { left: 20, top: 150 },
      { left: 50, top: 100 },
      { left: 0, top: 0 },
    ],
    delay: 1000,
    loop: true,
    config: {
      duration: 2500,
    },
  });

  return (
    <div className="relative h-96">
      <div className="absolute top-0 inset-0 justify-center inline-flex flex-row z-0">
        <div className="absolute inset-0 justify-center inline-flex flex-row">
          <animated.div
            className="bg-[#BF7F30] relative w-[300px] h-[300px] rounded-full opacity-50 blur-2xl pointer-events-none"
            style={{ ...shape1Springs }}
          ></animated.div>
          <animated.div
            className="bg-[#BFB634] relative w-[300px] h-[300px] rounded-full opacity-50 blur-2xl pointer-events-none"
            style={{ ...shape2Springs }}
          ></animated.div>
          <animated.div
            className="bg-[#F2CD5C] relative w-[300px] h-[300px] rounded-full opacity-50 blur-2xl pointer-events-none"
            style={{ ...shape3Springs }}
          ></animated.div>
        </div>
      </div>
      <div className="hero h-32">
        <div className="hero-content text-center text-[#0D0D0D] bg-transparent z-10 relative">
          <div className="max-w-md">
            <h1 className="mb-5 text-5xl font-bold">
              Gayrimenkul ihtiyaçlarınız için çözüm noktanız
            </h1>
            <p className="mb-5 text-lg ">
              En iyi gayrimenkul seçenekleri, sektördeki tecrübemiz ve kaliteli
              hizmet anlayışımız ile yanınızdayız.
            </p>
            <a href="#highlights" className="btn btn-primary">
              Daha Fazla Bilgi
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
