import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";

const CarouselSection = () => {
  const videoRef = useRef(null);

  // Video hızını ayarlamak için useEffect
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1;
      videoRef.current.play(); // Video başlatma işlemi mobil cihazlarda zorunlu olabilir
    }
  }, []);

  return (
    <div className="h-[800px] w-full overflow-hidden relative">
      {/* Video */}
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-[90%] object-cover"
        src="/EmlakLogo.mp4" // Video dosyasının yolu
        autoPlay
        loop
        muted
        playsInline // Mobilde videonun tam ekran olmadan oynatılmasını sağlar
      ></video>

      {/* Üstteki içerik */}
      <div
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
             bg-black/40 w-full max-w-full h-[30%]  flex flex-col p-6 z-40" // Mobil için daha fazla padding üstte
      >
        <img
          src="/favicon.png" // Logonuzun yolu
          alt="Logo"
          className="absolute top-0 left-1/2 z-10 transform -translate-x-1/2 -translate-y-1/4"
          style={{ width: "100vw", maxWidth: "400px", minWidth: "120px" }} // vw ile logo boyutlandırma
        />
        <motion.h1
          className="text-4xl md:text-5xl font-bold text-white text-center mt-20"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        ></motion.h1>

        <motion.p
          className="text-lg tracking-widest	 md:text-3xl mt-20 text-white text-center font-bold font-copperplate"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5 }}
        >
          {" "}
          TÜRKİYE´NİN ALTIN ANAHTARI
        </motion.p>
      </div>
    </div>
  );
};

export default CarouselSection;
