import React from "react";

const consultants = [
  {
    id: 1,
    name: "Ali Yetgin",
    title: "Yönetim Kurulu Başkanı",
    phone: "0532 120 69 09",
    email: "ahmet@example.com",
    image: "/AliYetgin.PNG",
  },
  {
    id: 2,
    name: "Tolga Şirip",
    title: "Gayrimenkul Danışmanı",
    phone: "0532 689 29 15",
    email: "ahmet@example.com",
    image: "/TolgaSirip.PNG",
  },
  {
    id: 3,
    name: "Nagihan Yetgin",
    title: "Gayrimenkul Danışmanı",
    phone: "0532 454 33 36",
    email: "mehmet@example.com",
    image: "/NAGİHAN.png",
  },

  {
    id: 4,
    name: "Bülent Tozo",
    title: "Gayrimenkul Danışmanı",
    phone: "0532 065 90 74",
    email: "ayse@example.com",
    image: "/BÜLENT.png",
  },

  {
    id: 5,
    name: "Nurten Yüksel",
    title: "Gayrimenkul Danışmanı",
    phone: "0553 145 10 50",
    email: "mehmet@example.com",
    image: "/NURTEN.png",
  },
  {
    id: 6,
    name: "Sadullah Karakaya",
    title: "Gayrimenkul Danışmanı",
    phone: "0535 729 79 10",
    email: "mehmet@example.com",
    image: "/SADULLAH.png",
  },
  {
    id: 7,
    name: "Umut Önel",
    title: "Gayrimenkul Danışmanı",
    phone: "0535 740 50 08",
    email: "mehmet@example.com",
    image: "/UMUT.png",
  },
  {
    id: 8,
    name: "Bahar Saka",
    title: "Gayrimenkul Danışmanı",
    phone: "0549 494 91 41",
    email: "mehmet@example.com",
    image: "/BAHAR.png",
  },
  {
    id: 9,
    name: "Serpe Simge Önel",
    title: "Gayrimenkul Danışmanı",
    phone: "0545 212 20 00",
    email: "mehmet@example.com",
    image: "/SERPE.png",
  },
  {
    id: 10,
    name: "Saliha Aymelek",
    title: "Gayrimenkul Danışmanı",
    phone: "0507 093 15 58",
    email: "mehmet@example.com",
    image: "/SALİHA.png",
  },
  {
    id: 11,
    name: "Ömer Zeytin",
    title: "Gayrimenkul Danışmanı",
    phone: "0530 289 23 92",
    email: "mehmet@example.com",
    image: "/ÖMER.png",
  },
  {
    id: 12,
    name: "Betül Baştürk",
    title: "Gayrimenkul Danışmanı",
    phone: "0507 292 73 14",
    email: "mehmet@example.com",
    image: "/BETÜL.png",
  },
  {
    id: 13,
    name: "Mutlu Metin",
    title: "Gayrimenkul Danışmanı",
    phone: "0507 309 50 00",
    email: "mehmet@example.com",
    image: "/MUTLU.png",
  },
  {
    id: 14,
    name: "Arda Yıldız",
    title: "Gayrimenkul Danışmanı",
    phone: "0505 075 20 21",
    email: "mehmet@example.com",
    image: "/ARDA.png",
  },
  {
    id: 15,
    name: "Sertaç Çakar",
    title: "Gayrimenkul Danışmanı",
    phone: "0533 385 73 93",
    email: "mehmet@example.com",
    image: "/SERTAÇ.png",
  },
  {
    id: 16,
    name: "Hakan Hızlı",
    title: "Gayrimenkul Danışmanı",
    phone: "0541 837 89 55",
    email: "mehmet@example.com",
    image: "/logoImage1.png",
  },
  {
    id: 17,
    name: "Fatih Turan",
    title: "Gayrimenkul Danışmanı",
    phone: "0540 438 19 86",
    email: "fatih@example.com",
    image: "/FatihTuran.png",
  },
];

const ConsultantsPage = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold text-center mb-8 tracking-wide">
        Danışmanlarımız
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {consultants.map((consultant) => (
          <div key={consultant.id} className="card bg-base-100 shadow-xl">
            <figure>
              <img
                src={consultant.image}
                alt={consultant.name}
                className="rounded-t-lg w-full h-48 object-cover"
              />
            </figure>
            <div className="card-body">
              <h2 className="card-title">{consultant.name}</h2>
              <p>
                <span className="font-bold"></span>
                {consultant.title}
              </p>
              <p>
                <span className="font-bold">Telefon: </span>
                {consultant.phone}
              </p>
              {/* <p>
                <span className="font-bold">E-posta: </span>
                {consultant.email}
              </p>*/}
              <div className="card-actions ">
                {/*<a
                  href={`mailto:${consultant.email}`}
                  className="btn btn-primary btn-sm"
                >
                  E-posta Gönder
                </a>*/}
                <a
                  href={`tel:+9${consultant.phone.replace(/[^0-9]/g, "")}`}
                  className="btn btn-primary btn-sm "
                >
                  Ara
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConsultantsPage;
