import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [isHomePage, setIsHomePage] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setIsHomePage(location.pathname === "/");
  }, [location]);

  return (
    <div
      className={
        "navbar z-10 px-8 py-4 flex flex-col lg:flex-row justify-between items-center" +
        (isHomePage
          ? " absolute bg-white text-gkBlack"
          : " bg-white text-gkBlack")
      }
    >
      {/* Logo */}
      <div className="flex-1 flex justify-center lg:justify-start mb-4 lg:mb-0">
        <Link to="/" className="normal-case text-xl">
          <img
            src="/shadowed.png"
            alt="Golden Keys Real Estate Logo"
            className="h-14"
          />
        </Link>
      </div>

      {/* Menü */}
      <div>
        <ul className="flex space-x-3 lg:space-x-6 uppercase text-lg font-light">
          <li>
            <Link to="/ilanlar" className="hover:text-gold">
              İlanlar
            </Link>
          </li>
          <li>
            <Link to="/danismanlar" className="hover:text-gold">
              Danışmanlarımız
            </Link>
          </li>
          <li>
            <Link to="/hakkimizda" className="hover:text-gold">
              Hakkımızda
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
