// src/components/Footer.js

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer bg-base-300 text-base-content p-16 mt-4 ">
      <nav>
        <h6 className="footer-title">GAYRİMENKULLER</h6>
        <Link to="/ilanlar" className="link link-hover">
          Konutlar
        </Link>
        <Link to="/ilanlar" className="link link-hover">
          Arsalar
        </Link>{" "}
        <Link to="/ilanlar" className="link link-hover">
          Ticari Gayrimenkuller
        </Link>
      </nav>
      <nav>
        <h6 className="footer-title">GOLDEN KEYS HAKKINDA</h6>
        <Link to="/hakkimizda" className="link link-hover">
          Hakkımızda
        </Link>
        <Link to="/subeler" className="link link-hover">
          Ofislerimiz
        </Link>
        <Link to="/danismanlar" className="link link-hover">
          Danışmanlarımız
        </Link>
      </nav>
      <nav>
        <h6 className="footer-title">Sosyal Medya</h6>
        <a
          href="https://www.youtube.com/@goldenkeys_realestate"
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center items-center hover:underline"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 20"
            className="fill-current "
          >
            {" "}
            <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
          </svg>
          <span className="ml-1">/</span>
          <p className="text-sm align-middle">goldenkeysrealestate</p>
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100089637871286"
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center items-center hover:underline"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="fill-current flex-shrink-0"
          >
            <path d="M22.675 0h-21.35c-.733 0-1.325.592-1.325 1.325v21.351c0 .733.592 1.325 1.325 1.325h11.495v-9.284h-3.122v-3.622h3.122v-2.672c0-3.1 1.892-4.785 4.655-4.785 1.325 0 2.464.099 2.796.143v3.24l-1.918.001c-1.504 0-1.794.715-1.794 1.763v2.311h3.587l-.467 3.622h-3.12v9.283h6.116c.733 0 1.325-.592 1.325-1.325v-21.35c0-.733-.592-1.325-1.325-1.325z" />
          </svg>
          <span className="ml-1">/</span>
          <p className="text-sm align-middle ">goldenkeysrealestate</p>
        </a>
        <a
          href="https://goldenkeysrealestate.sahibinden.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center items-center hover:underline"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="fill-current"
          >
            <path d="M0 0v24h24V0zm11.517 4.723c.563-.007 1.13-.004 1.69.063 2.412.054 4.853 2.18 4.879 4.508h-3.319c.009-.694-.603-1.555-1.279-1.732-1.105-.269-2.46-.355-3.43.294-.738.445-1.065 1.672-.095 2.056 2.288 1.083 5.158.846 7.224 2.372 1.698 1.21 1.598 3.666.274 5.086-1.718 1.84-4.636 2.132-7.099 1.782-2.448-.117-4.755-2.245-4.819-4.562h3.311c-.056.832.638 1.557 1.46 1.822 1.27.275 2.726.358 3.93-.19.96-.323 1.024-1.544.284-2.103-1.595-.897-3.565-.924-5.297-1.518-2.012-.39-3.643-2.278-3.26-4.197.424-2.342 3.127-3.727 5.546-3.681z" />
          </svg>
          <span className="ml-1">/</span>
          <p className="text-sm align-middle ">goldenkeysrealestate</p>
        </a>
        <a
          href="https://goldenkeysrealestatebergama.sahibinden.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center items-center hover:underline"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="fill-current"
          >
            <path d="M0 0v24h24V0zm11.517 4.723c.563-.007 1.13-.004 1.69.063 2.412.054 4.853 2.18 4.879 4.508h-3.319c.009-.694-.603-1.555-1.279-1.732-1.105-.269-2.46-.355-3.43.294-.738.445-1.065 1.672-.095 2.056 2.288 1.083 5.158.846 7.224 2.372 1.698 1.21 1.598 3.666.274 5.086-1.718 1.84-4.636 2.132-7.099 1.782-2.448-.117-4.755-2.245-4.819-4.562h3.311c-.056.832.638 1.557 1.46 1.822 1.27.275 2.726.358 3.93-.19.96-.323 1.024-1.544.284-2.103-1.595-.897-3.565-.924-5.297-1.518-2.012-.39-3.643-2.278-3.26-4.197.424-2.342 3.127-3.727 5.546-3.681z" />
          </svg>
          <span className="ml-1">/</span>
          <p className="text-sm align-middle ">goldenkeysrealestatebergama</p>
        </a>
        <a
          href="https://www.instagram.com/goldenkeysrealestate"
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center items-center hover:underline"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 20"
            className="fill-current"
          >
            {" "}
            <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM12 15.88C9.86 15.88 8.12 14.14 8.12 12C8.12 9.86 9.86 8.12 12 8.12C14.14 8.12 15.88 9.86 15.88 12C15.88 14.14 14.14 15.88 12 15.88ZM17.92 6.88C17.87 7 17.8 7.11 17.71 7.21C17.61 7.3 17.5 7.37 17.38 7.42C17.26 7.47 17.13 7.5 17 7.5C16.73 7.5 16.48 7.4 16.29 7.21C16.2 7.11 16.13 7 16.08 6.88C16.03 6.76 16 6.63 16 6.5C16 6.37 16.03 6.24 16.08 6.12C16.13 5.99 16.2 5.89 16.29 5.79C16.52 5.56 16.87 5.45 17.19 5.52C17.26 5.53 17.32 5.55 17.38 5.58C17.44 5.6 17.5 5.63 17.56 5.67C17.61 5.7 17.66 5.75 17.71 5.79C17.8 5.89 17.87 5.99 17.92 6.12C17.97 6.24 18 6.37 18 6.5C18 6.63 17.97 6.76 17.92 6.88Z" />
          </svg>
          <span className="ml-1">/</span>
          <p className="text-sm align-middle">goldenkeysrealestate</p>
        </a>
      </nav>
    </footer>
  );
};

export default Footer;
