// src/pages/PropertyDetailPage.js
import React from "react";
import { useParams } from "react-router-dom";

const PropertyDetailPage = () => {
  const { id } = useParams();

  // Örnek emlak verisi (bu veriyi gerçek bir API'den alacağız)
  const property = {
    id,
    title: `Konut ${id}`,
    category: "Konut",
    price: 500000,
    location: "İstanbul",
    description:
      "Bu mülk harika bir yaşam alanı sunuyor. Modern tasarımı ve geniş alanlarıyla çok konforlu. Çok merkezi bir konumda yer alıyor.",
    images: ["/image1-1.jpeg", "/image1-2.jpg"],
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="flex flex-col md:flex-row">
        {/* Sol taraf: Resimler */}
        <div className="md:w-1/2 mb-4 md:mb-0">
          <img
            src={property.images[0]}
            alt={property.title}
            className="w-full h-64 object-cover rounded-lg"
          />
          <div className="mt-4 flex space-x-2">
            {property.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`image-${index}`}
                className="w-24 h-24 object-cover rounded-lg"
              />
            ))}
          </div>
        </div>

        {/* Sağ taraf: Detaylar */}
        <div className="md:w-1/2 md:pl-6">
          <h1 className="text-3xl font-semibold">{property.title}</h1>
          <p className="text-xl text-gray-600 mt-2">{property.location}</p>
          <p className="text-xl text-gray-900 mt-2">{property.price}₺</p>
          <p className="mt-4">{property.description}</p>
          <button className="btn btn-primary mt-6">İletişime Geç</button>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailPage;
