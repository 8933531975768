import { motion } from "framer-motion";
import FranchiseSection from "../components/FranchiseSection.jsx";
import CarouselSection from "../components/CarouselSection.jsx";
import MainSection from "../components/MainSection.jsx";
import PortfolioSection from "../components/PortfolioSection.jsx";
import Highlights from "../components/Highlights.jsx";
import BrokersSection from "../components/BrokersSection.jsx";
import FranciseInfo from "../components/FranciseInfo.jsx";
import Offices from "../components/Offices.jsx";

// Animasyon varyantlarını belirliyoruz.
const sectionVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
};

const HomePage = () => {
  return (
    <div>
      <CarouselSection />
      <div className="container mx-auto pt-40 space-y-40 relative z-10">
        <FranciseInfo />
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.6 }} // Animasyon sadece görünür olduğunda başlayacak
          variants={sectionVariants}
        >
          <FranchiseSection />
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.6 }}
          variants={sectionVariants}
        >
          <MainSection />
        </motion.div>

        {/* PortfolioSection için animasyon */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.6 }}
          variants={sectionVariants}
        >
          <PortfolioSection />
        </motion.div>

        {/* Highlights için animasyon */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={sectionVariants}
        >
          <Highlights />
        </motion.div>

        {/* BrokersSection için animasyon */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.6 }}
          variants={sectionVariants}
        >
          <BrokersSection />
        </motion.div>

        {/* Offices için animasyon */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={sectionVariants}
        >
          <Offices />
        </motion.div>
      </div>
    </div>
  );
};

export default HomePage;
