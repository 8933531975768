import ScrollToTop from "./components/ScrollToTop";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import ListingsPage from "./pages/ListingsPage";
import PropertyDetailPage from "./pages/PropertyDetailPage";
import "./index.css";
import Footer from "./components/Footer";
import ConsultantsPage from "./pages/ConsultantsPage";
import AboutPage from "./pages/AboutPage";
import OfficesPage from "./pages/OfficesPage";

function App() {
  return (
    <div className="App bg-ivory">
      <Router>
        <Header />
        <MainContent />
        <Footer />
      </Router>
    </div>
  );
}

const MainContent = () => {
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/ilanlar" element={<ListingsPage />} />
        <Route path="/property/:id" element={<PropertyDetailPage />} />
        <Route path="/danismanlar" element={<ConsultantsPage />} />
        <Route path="/hakkimizda" element={<AboutPage />} />
        <Route path="/subeler" element={<OfficesPage />} />
      </Routes>
    </div>
  );
};

export default App;
