import React from "react";
import { Link } from "react-router-dom";

const BrokersSection = () => {
  return (
    <section className="relative shadow-sm border rounded-lg border-neutral-next-100 bg-white overflow-hidden">
      <div
        className="h-[400px] w-[700px] bg-cover bg-center bg-right bg-no-repeat mr-0 ml-auto"
        style={{ backgroundImage: "url('/DanismanLogo.png')" }}
      ></div>
      <div className="absolute inset-0 bg-black opacity-40 lg:opacity-0"></div>
      <div className="absolute top-20 left-7 ">
        <div className="max-w-md">
          <h1 className="mb-5 text-5xl font-bold text-white lg:text-black">
            Danışmanlarımız
          </h1>
          <p className="mb-5 text-lg text-white lg:text-black">
            Sizlere en doğru ve güvenilir tavsiyeleri sunmak için burada olan
            uzman ekibimiz, ihtiyaçlarınıza en uygun çözümleri hızlıca
            sunacaktır.{" "}
          </p>
          <Link to="/danismanlar" className="btn btn-primary">
            <p>Danışmanlarımız</p>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BrokersSection;
