import React, { useState, useEffect } from "react";

const FranchiseForm = ({ closeModal }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    birthDate: "",
    education: "",
    country: "",
    city: "",
    district: "",
    realEstateExperience: "",
    currentSector: "",
    officeOpeningTime: "",
    desiredOfficeLocation: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  // Pop-up açıldığında arka sayfanın kaydırılmasını engelleme
  useEffect(() => {
    document.body.style.overflow = "hidden"; // Sayfa kaydırmasını devre dışı bırak
    return () => {
      document.body.style.overflow = "auto"; // Pop-up kapatıldığında kaydırmayı geri aç
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    setIsSubmitted(true);

    // Modal'ı kapatmak için closeModal'ı çağırabilirsiniz (isteğe bağlı)
    if (closeModal) {
      closeModal();
    }
  };

  if (isSubmitted) {
    return (
      <div className="text-center p-6 bg-success text-white rounded-lg">
        Başvurunuz başarıyla alındı! En kısa sürede sizinle iletişime geçeceğiz.
      </div>
    );
  }

  return (
    <div className="modal-container">
      <div className="modal-content">
        <form onSubmit={handleSubmit} className="card bg-base-100 ">
          {/* Close Button */}
          <button
            type="button"
            onClick={closeModal}
            className="absolute top-2 right-2 text-xl text-gray-500 hover:text-black"
          >
            <span className="font-bold">✖</span>
          </button>
          <h2 className=" font-bold text-gold text-center text-lg lg:text-2xl">
            GOLDEN KEYS
          </h2>
          <h2 className=" font-bold text-gold mb-8 text-center text-lg lg:text-2xl">
            Franchise Başvuru Formu
          </h2>

          {/* Ad Soyad */}
          <div className="form-control mb-4">
            <label className="label">
              <span className="label-text">Ad Soyad</span>
            </label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Adınızı ve Soyadınızı Giriniz"
              className="input input-bordered w-full"
              required
            />
          </div>

          {/* Email */}
          <div className="form-control mb-4">
            <label className="label">
              <span className="label-text">E-posta</span>
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="E-posta Adresinizi Giriniz"
              className="input input-bordered w-full"
              required
            />
          </div>

          {/* Telefon Numarası */}
          <div className="form-control mb-4">
            <label className="label">
              <span className="label-text">Telefon Numarası</span>
            </label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Telefon Numaranızı Giriniz"
              className="input input-bordered w-full"
              required
            />
          </div>

          {/* Doğum Tarihi */}
          <div className="form-control mb-4">
            <label className="label">
              <span className="label-text">Doğum Tarihi</span>
            </label>
            <input
              type="date"
              name="birthDate"
              value={formData.birthDate}
              onChange={handleChange}
              className="input input-bordered w-full"
              required
            />
          </div>

          {/* Eğitim Durumu */}
          <div className="form-control mb-4">
            <label className="label">
              <span className="label-text">Eğitim Durumu</span>
            </label>
            <select
              name="education"
              value={formData.education}
              onChange={handleChange}
              className="select select-bordered w-full"
              required
            >
              <option value="">Eğitim Durumunuzu Seçiniz</option>
              <option value="İlkokul">İlkokul</option>
              <option value="Ortaokul">Ortaokul</option>
              <option value="Lise">Lise</option>
              <option value="Üniversite">Üniversite</option>
              <option value="Yüksek Lisans/Doktora">
                Yüksek Lisans/Doktora
              </option>
            </select>
          </div>

          {/* Ülke ve Şehir */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div className="form-control">
              <label className="label">
                <span className="label-text">Ülke</span>
              </label>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
                placeholder="Ülke"
                className="input input-bordered w-full"
                required
              />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Şehir</span>
              </label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="Şehir"
                className="input input-bordered w-full"
                required
              />
            </div>
          </div>

          {/* İl ve İlçe */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div className="form-control">
              <label className="label">
                <span className="label-text">İl</span>
              </label>
              <input
                type="text"
                name="district"
                value={formData.district}
                onChange={handleChange}
                placeholder="İl"
                className="input input-bordered w-full"
                required
              />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">İlçe</span>
              </label>
              <input
                type="text"
                name="town"
                value={formData.town}
                onChange={handleChange}
                placeholder="İlçe"
                className="input input-bordered w-full"
                required
              />
            </div>
          </div>

          {/* Gayrimenkul Tecrübesi */}
          <div className="form-control mb-4">
            <label className="label">
              <span className="label-text">
                Daha Önce Gayrimenkul Sektörü Tecrübeniz Oldu Mu?
              </span>
            </label>
            <select
              name="realEstateExperience"
              value={formData.realEstateExperience}
              onChange={handleChange}
              className="select select-bordered w-full"
              required
            >
              <option value="">Seçiniz</option>
              <option value="Evet">Evet</option>
              <option value="Hayır">Hayır</option>
            </select>
          </div>

          {/* Çalıştığınız Sektör */}
          <div className="form-control mb-4">
            <label className="label">
              <span className="label-text">Şu An Çalıştığınız Sektör</span>
            </label>
            <input
              type="text"
              name="currentSector"
              value={formData.currentSector}
              onChange={handleChange}
              placeholder="Sektör"
              className="input input-bordered w-full"
              required
            />
          </div>

          {/* Ofis Açma Süresi */}
          <div className="form-control mb-4">
            <label className="label">
              <span className="label-text">
                Ne Kadar Sürede Ofis Açmayı Planlıyorsunuz?
              </span>
            </label>
            <input
              type="text"
              name="officeOpeningTime"
              value={formData.officeOpeningTime}
              onChange={handleChange}
              placeholder="Örneğin: 3-6 Ay"
              className="input input-bordered w-full"
              required
            />
          </div>

          {/* İstenen Ofis Lokasyonu */}
          <div className="form-control mb-6">
            <label className="label">
              <span className="label-text">Ofis Açmak İstediğiniz İl-İlçe</span>
            </label>
            <input
              type="text"
              name="desiredOfficeLocation"
              value={formData.desiredOfficeLocation}
              onChange={handleChange}
              placeholder="Örneğin: İstanbul - Kadıköy"
              className="input input-bordered w-full"
              required
            />
          </div>

          <button type="submit" className="btn btn-primary w-full">
            Başvuruyu Gönder
          </button>
        </form>
      </div>
    </div>
  );
};

export default FranchiseForm;
