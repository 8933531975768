import React from "react";
import Offices from "../components/Offices.jsx";

const OfficesPage = () => {
  return (
    <div className="container mx-auto py-10 space-y-10">
      <Offices />
    </div>
  );
};

export default OfficesPage;
